<template>
  <el-form ref="AccountFrom" :model="account" :rules="rules" label-width="0px" class="demo-ruleForm login-container">
    <h3 class="title">幸福绩效管理后台</h3>
    <el-form-item prop="username">
      <el-input type="text" v-model.trim="account.username" auto-complete="off" placeholder="账号"></el-input>
    </el-form-item>
    <el-form-item prop="imgCode" style="position: relative" v-if="showImgCode">
      <el-input type="text" v-model="account.imgCode" auto-complete="off" placeholder="图形验证码"></el-input>
      <div class="imgyzm"><img :src="imgCode" @click="getImgCode" width="100px" height="36px" /></div>
    </el-form-item>
    <el-form-item prop="pwd">
      <el-input type="password" v-model="account.pwd" auto-complete="off" placeholder="密码" @keyup.enter.native="handleLogin"></el-input>
    </el-form-item>
    <el-form-item style="width:100%;">
      <el-button type="primary" style="width:100%;" @click.native.prevent="handleLogin" :loading="loginLoading">登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { login, secret, getImgCode } from '@/api/api_public'
import security from '@/utils/security.min'
export default {
  data() {
    return {
      loginLoading: false,
      account: {
        username: '',
        pwd: '',
        imgCode: ''
      },
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      checked: true,
      secret: null,
      imgId: null,
      imgCode: null,
      showImgCode: false
    }
  },
  mounted() {
    secret().then(res => {
      if (res && res.code === '000000') {
        this.secret = res.data
      }
    })
  },
  methods: {
    handleLogin() {
      this.$refs.AccountFrom.validate(async valid => {
        if (valid) {
          this.loginLoading = true
          let loginParams = {
            mobile: this.account.username,
            password: this.account.pwd,
            imgCode: this.account.imgCode,
            imgId: this.imgId
          }
          loginParams.password = security.encrypty(this.secret.modulu, this.secret.exponent, loginParams.password)
          let result = await login(loginParams)
          this.loginLoading = false
          if (result && result.code === '000000') {
            localStorage.setItem('token', result.data.token)
            localStorage.setItem('companyList', result.data.companyList)
            this.$router.push({ path: '/' })
          } else if (result.code === '001108' || result.code === '001113' || result.code === '001114') {
            this.showImgCode = true
            this.getImgCode()
          }
        }
      })
    },
    getImgCode() {
      getImgCode().then(result => {
        if (result && result.code === '000000') {
          this.imgCode = result.data.imgCode
          this.imgId = result.data.imgId
        }
      })
    }
  }
}
</script>
<style>
body {
  background: #dfe9fb;
}
</style>
<style lang="scss" scoped>
.login-container {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 160px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;

  background: -ms-linear-gradient(top, #ace, #00c1de); /* IE 10 */
  background: -moz-linear-gradient(top, #ace, #00c1de); /*火狐*/
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ace), to(#00c1de)); /*谷歌*/
  background: -webkit-linear-gradient(top, #ace, #00c1de); /*Safari5.1 Chrome 10+*/
  background: -o-linear-gradient(top, #ace, #00c1de); /*Opera 11.10+*/

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0px 0px 35px 0px;
  }
  .imgyzm {
    position: absolute;
    right: 2px;
    bottom: 2px;
    img {
      display: block;
    }
  }
}
</style>
